import type { CreateJournalTarget } from '@/journal'

import { createConsoleTarget } from '@/journal'
import { createSentryTarget } from '../sentryTarget/createSentryTarget'
import { createPendoTarget } from '../createPendoTarget'

export function createJournalTargets() {
  const targets: CreateJournalTarget<any>[] = []

  // CONSOLE TARGET
  targets.push(
    createConsoleTarget({
      consoleOutput:
        process.env.NODE_ENV === 'development'
          ? 'normal'
          : process.env.NODE_ENV === 'test'
            ? 'silent'
            : 'normal',
    }),
  )

  // SENTRY TARGET
  targets.push(createSentryTarget('sentry')({}))

  // PENDO TARGET
  targets.push(createPendoTarget()({}))

  return targets
}
